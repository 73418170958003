import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFilter } from '../../auth/FilterContext';

const FilterBy = () => {
    const { sort, filterDispatch } = useFilter();

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        filterDispatch({ type: 'UPDATE_SORT', payload: selectedValue });

    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }} size="small" style={{ marginLeft: '0' }}>
            <InputLabel id="demo-select-small-label">Sort By</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={sort}
                label="Sort By"
                onChange={handleChange}
                MenuProps={{
                    disableScrollLock: true,
                }}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value="priceLowToHigh">Price low to high</MenuItem>
                <MenuItem value="priceHighToLow">Price high to low</MenuItem>
            </Select>
        </FormControl>
    );
};

export default FilterBy;
