import React, { useState, useEffect } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Button } from '@mui/material';
import { getOrderStatusDetails } from '../ApiToFatch';

const OrderStatus = ({ orderId, orders }) => {
    const [orderStatusDetails, setOrderStatusDetails] = useState(null);
    const [refreshCount, setRefreshCount] = useState(0); // State to trigger refresh
    const [refreshing, setRefreshing] = useState(false); // State to track refresh state


    useEffect(() => {
        const fetchOrderStatusDetails = async () => {
            setRefreshing(true); // Set refreshing to true when refresh starts
            try {
                const response = await getOrderStatusDetails(orderId);
                setOrderStatusDetails(response);
            } catch (error) {
                console.error('Error fetching order status details:', error);
            } finally {
                // Simulate delay for refreshing state
                setTimeout(() => {
                    setRefreshing(false); // Set refreshing to false after delay
                }, 1000); // Adjust delay time as needed (1000ms = 1 second)
            }
        };

        fetchOrderStatusDetails();
        // eslint-disable-next-line
    }, [orderId, refreshCount]); // Depend on orderId and refreshCount changes

    const order = orders.find(order => order.id === orderId);

    const handleRefresh = () => {
        setRefreshCount(prevCount => prevCount + 1); // Increment refreshCount to trigger useEffect
    };

    if (!order) {
        return <Typography variant="h6">Order not found</Typography>;
    }

    // Mapping status titles to corresponding steps
    const statusMap = {
        'Pending': 0,
        'Accepted': 1,
        'Packaging': 2,
        'Dispatched': 3,
        'Delivered': 4
    };

    const activeStep = statusMap[order.status_title];

    return (
        <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
            <Typography variant="h6" align="center" gutterBottom>
                ORDER ID: {order.formatted_order_id}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                <img src="order-status-image.png" alt="Order Status" style={{ width: 100 }} />
            </Box>
            <Stepper activeStep={activeStep} orientation="vertical">
                {Object.keys(statusMap).map((status, index) => (
                    <Step key={index}>
                        <StepLabel>{status}</StepLabel>
                        {orderStatusDetails && orderStatusDetails.status_title === status && (
                            <Typography variant="body2">
                                Updated At: {new Date(orderStatusDetails.datetime).toLocaleString()}
                            </Typography>
                        )}
                    </Step>
                ))}
            </Stepper>
            <Button
                variant="contained"
                onClick={handleRefresh}
                disabled={refreshing} // Disable button when refreshing
                sx={{marginTop: '20px'}}
            >
                {refreshing ? 'Refreshing...' : 'Refresh Status'}
            </Button>
        </Box>
    );
};

export default OrderStatus;
