// import { json } from "react-router-dom";
// import { jwtDecode } from "jwt-decode";

export const apiUrl = process.env.REACT_APP_FETCH_FROM_DJANGO_API_URL;


export const handleRegisterSubmit = async (credentials, navigate, showAlert, handleClose, setErrors) => {
  try {
    const response = await fetch(`${apiUrl}/register/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    const json = await response.json();

    if (response.ok) {
      // Show success alert for OTP
      showAlert('success', 'Now confirm OTP to complete registration.');
      return { ok: true };
    } else {
      // Handle specific errors
      if (json.error === 'A user with this phone number already exists.') {
        showAlert('error', 'A user with this phone number already exists. Please log in or use a different phone number.');
      } else if (json.message === 'OTP already sent to this phone number. Please check your phone.') {
        showAlert('warning', 'You have requested too many times. Try again after 5 minutes.');
      } else {
        // Handle validation or other errors
        setErrors({
          username: json.username ? json.username.join(' ') : '',
          emailOrPhone: json.emailOrPhone ? json.emailOrPhone.join(' ') : '',
          password: json.password ? json.password.join(' ') : '',
        });
        // Show general error alert
        showAlert('error', 'Registration failed! Please check your details and try again.');
      }
      return { ok: false };
    }
  } catch (error) {
    // Handle fetch error
    console.error('Fetch error:', error);
    // Show error alert
    showAlert('error', 'Registration failed! Please try again later.');
    return { ok: false };
  }
};




// for seller or vendors registration
export const handleRegisterSubmitSeller = async (credentials, navigate, showAlert, setErrors) => {
  try {
    const response = await fetch(`${apiUrl}/register/vendors/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    const json = await response.json();

    if (response.ok) {
      // Show success alert for OTP
      showAlert('success', 'Now Confirm OTP to complete registration.');

      // Optional: Redirect to login page after a short delay
      // setTimeout(() => {
      //   navigate('/seller-login');
      // }, 1000);

      return { ok: true };
    } else {
      // Handle specific errors
      if (json.error === 'A user with this phone number already exists. Please use a different phone number or log in.') {
        showAlert('error', 'A user with this phone number already exists. Please log in or use a different phone number.');
      } else if (json.message === 'OTP already sent to this phone number. Please check your phone.') {
        showAlert('warning', 'You have requested too many times. Try again after 5 minutes.');
      } else {
        // Handle validation or other errors
        setErrors({
          username: json.username ? json.username.join(' ') : '',
          emailOrPhone: json.emailOrPhone ? json.emailOrPhone.join(' ') : '',
          password: json.password ? json.password.join(' ') : '',
        });
        showAlert('error', 'Registration failed! Please check your details and try again.');
      }

      return { ok: false };
    }
  } catch (error) {
    // Handle fetch error
    console.error('Fetch error:', error);
    showAlert('error', 'Registration failed! Please try again later.');
    return { ok: false };
  }
};



// otp verification 
export const handleOtpSubmit = async ({ identifier, otp, navigate, showAlert, setErrors , handleClose}) => {
  try {
    const response = await fetch(`${apiUrl}/verify-otp/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ identifier, otp }),
    });

    const json = await response.json();

    if (response.ok) {
      // Successfully verified OTP
      // Close the login dialog
      handleClose();
      // Redirect to the login page
      navigate('/');

      showAlert('success', 'OTP verified successfully! Welcome!');
      return { ok: true };
    } else {
      // Handle OTP verification failure
      setErrors({
        otp: json.otp ? json.otp.join(' ') : 'Invalid OTP. Please try again.',
      });
      showAlert('error', 'OTP verification failed! Please check the OTP and try again.');
      return { ok: false };
    }
  } catch (error) {
    // Handle fetch error
    console.error('Fetch error:', error);
    setErrors({ otp: 'An error occurred. Please try again later.' });
    showAlert('error', 'OTP verification failed! Please try again later.');
    return { ok: false };
  }
};

// otp verification 
export const handleOtpSubmitseller = async ({ identifier, otp, navigate, showAlert, setErrors }) => {
  try {
    const response = await fetch(`${apiUrl}/verify-otp-vendors/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ identifier, otp }),
    });

    const json = await response.json();

    if (response.ok) {
      // Successfully verified OTP
      // Redirect to the login page
      setTimeout(() => {
        navigate('/seller-login');
      }, 1000); // Adjust delay if needed
      
      showAlert('success', 'OTP verified successfully! Welcome!');
      return { ok: true };
    } else {
      // Handle OTP verification failure
      setErrors({
        otp: json.otp ? json.otp.join(' ') : 'Invalid OTP. Please try again.',
      });
      showAlert('error', 'OTP verification failed! Please check the OTP and try again.');
      return { ok: false };
    }
  } catch (error) {
    // Handle fetch error
    console.error('Fetch error:', error);
    setErrors({ otp: 'An error occurred. Please try again later.' });
    showAlert('error', 'OTP verification failed! Please try again later.');
    return { ok: false };
  }
};


// using only for & as vendors login api endpoint 
export const handleLoginSubmit = async (logincredentials, navigate, setIsLoggedIn, showAlert, setUsername, setErrors) => {
  try {
    const response = await fetch(`${apiUrl}/api/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logincredentials),
      credentials: 'include',
    });

    const responseData = await response.json();

    if (response.ok) {

      // Fetch user details
      const userData = await getSingleUser();

      // Check if the user's role is "vendor"
      if (userData.role === 'vendors') {
        setUsername(userData);
        setIsLoggedIn(true);

        navigate('/');

        // Show success alert
        showAlert('success', 'Login successful!');

      } else {
        // User is not a vendor, show an error alert and log them out
        setIsLoggedIn(false);

        // Show error alert
        showAlert('error', 'Invalid user! Only Sellers are allowed to log in here.');
      }
    } else {
      setErrors({
        username: responseData.username ? responseData.username.join(' ') : '',
        email: responseData.email ? responseData.email.join(' ') : '',
        password: responseData.password ? responseData.password.join(' ') : '',
      });
      // Login failed, handle accordingly
      console.error('Login failed:', responseData);
      // Show error alert
      showAlert('error', responseData.detail || 'Login failed! Please check your details and try again.');
    }
  } catch (error) {
    // Handle fetch error
    console.error('Fetch error:', error);
    // Show error alert
    showAlert('error', 'Login failed! Please try again later.');
  }
};


// logout submit
export const handleUserLogout = async (setIsLoggedIn, showAlert) => {
  try {
    const response = await fetch(`${apiUrl}/logout/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Ensure cookies are included in the request
    });

    if (response.ok) {
      // Clear any client-side state or cookies related to authentication
      setIsLoggedIn(false);  // Update your application state
      showAlert('success', 'Successfully logged out.');
    } else {
      showAlert('error', 'Logout failed!');
    }
  } catch (error) {
    console.error('Logout error:', error);
    showAlert('error', 'An error occurred during logout.');
  }
};


// export const getSingleUser = async (accessToken) => {
//   try {
//     if (!accessToken) {
//       console.error("No access token found!");
//       return []; // Return an empty array if no access token is found
//     }

//     // Make the fetch request with the access token in the Authorization header
//     const response = await fetch(`${apiUrl}/get-user-details/`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${accessToken}`, // Correctly passing the token here
//       },
//       credentials: 'include', 
//       withCredentials: true// Include cookies in the request
//     });

//     if (response.ok) {
//       const responseBody = await response.json();
//       return responseBody;
//     } else {
//       console.error("HTTP error! Status:", response.status);
//       return []; // Return an empty array on error
//     }
//   } catch (error) {
//     console.error("Error fetching user data:", error);
//     return []; // Return an empty array on error
//   }
// };

export const getSingleUser = async () => {
  try {
    // Make the fetch request with the access token in the Authorization header
    const response = await fetch(`${apiUrl}/get-user-details/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      return []; // Return an empty array on error
    }
  } catch (error) {
    return []; // Return an empty array on error
  }
};




export const getOrdersByUser = async () => {
  try {
    // Make the fetch request with the access token in the Authorization header
    const response = await fetch(`${apiUrl}/ordersbyuser/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return []; // Return an empty array on error
  }
};


export const getOrderStatusDetails = async (orderId) => {
  try {

    // Make the fetch request with the access token in the Authorization header
    const response = await fetch(`${apiUrl}/orderstatusdetails/${orderId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return []; // Return an empty array on error
  }
};

export const updateUser = async ( id, updatedUserData) => {
  try {

    // Make the fetch request with the access token in the Authorization header
    const response = await fetch(`${apiUrl}/users/${id}/update/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedUserData),
      credentials: 'include',
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error updating data:", error);
    return []; // Return an empty array on error
  }
};


export const getAllCategories = async () => {
  try {
    const response = await fetch(`${apiUrl}/categories/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching categories:", error);
    return []; // Return an empty array on error
  }
};

export const getAllBrands = async () => {
  try {
    const response = await fetch(`${apiUrl}/brands/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching brands:", error);
    return []; // Return an empty array on error
  }
};


export const getAllSliderItems = async () => {
  try {
    const response = await fetch(`${apiUrl}/slideritem/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching SliderItems:", error);
    return []; // Return an empty array on error
  }
};


export const getAllProducts = async (page = 1, itemsPerPage = 5) => {
  try {
    const response = await fetch(`${apiUrl}/products/?page=${page}&page_size=${itemsPerPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return { total: 0, items: [] }; // Return an empty object on error
    }
  } catch (error) {
    console.error("Error fetching all products:", error);
    return { total: 0, items: [] }; // Return an empty object on error
  }
};


export const getProductBySubcategory = async () => {
  try {
    const response = await fetch(`${apiUrl}/products/subcategory/<int:subcategory_id>/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching categories:", error);
    return []; // Return an empty array on error
  }
};


export const getProductByNewArival = async () => {
  try {
    const response = await fetch(`${apiUrl}/products/newarival/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching new arival products:", error);
    return []; // Return an empty array on error
  }
};

export const getProductByNewArivalPage = async (currentPage, itemsPerPage) => {
  try {
    const response = await fetch(`${apiUrl}/products/new-arrival/?page=${currentPage}&page_size=${itemsPerPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return { results: [], count: 0 }; // Return an empty array and count 0 on error
    }
  } catch (error) {
    console.error("Error fetching new arrival products:", error);
    return { results: [], count: 0 }; // Return an empty array and count 0 on error
  }
};


export const getAllFeaturedCategories = async () => {
  try {
    const response = await fetch(`${apiUrl}/categories/featured/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching featured categories:", error);
    return []; // Return an empty array on error
  }
};

export const getProductByArival = async () => {
  try {
    const response = await fetch(`${apiUrl}/products/newarival/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching products by arival:", error);
    return []; // Return an empty array on error
  }
};


export const getBasicInfo = async () => {
  try {
    const response = await fetch(`${apiUrl}/basicsetup/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error basic information:", error);
    return []; // Return an empty array on error
  }
};


export const getFaq = async () => {
  try {
    const response = await fetch(`${apiUrl}/faq/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error Faq:", error);
    return []; // Return an empty array on error
  }
};

export const getAboutus = async () => {
  try {
    const response = await fetch(`${apiUrl}/aboutus/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error about us:", error);
    return []; // Return an empty array on error
  }
};

export const getPrivacyPolicy = async () => {
  try {
    const response = await fetch(`${apiUrl}/privacypolicy/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error privacy policy:", error);
    return []; // Return an empty array on error
  }
};

export const getTermsOfUse = async () => {
  try {
    const response = await fetch(`${apiUrl}/termsofuse/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error terms of use:", error);
    return []; // Return an empty array on error
  }
};

export const getContactUs = async () => {
  try {
    const response = await fetch(`${apiUrl}/contactus/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error contact us:", error);
    return []; // Return an empty array on error
  }
};


export const getAllProductsForSearch = async () => {
  try {
    const response = await fetch(`${apiUrl}/search/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return { total: 0, items: [] }; // Return an empty object on error
    }
  } catch (error) {
    console.error("Error fetching all products:", error);
    return { total: 0, items: [] }; // Return an empty object on error
  }
};


export const getWishlistItems = async () => {
  try {

    // Make the fetch request with the access token in the Authorization header
    const response = await fetch(`${apiUrl}/wishlist-items/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return []; // Return an empty array on error
  }
};


// export const createReviews = async (authTokens) => {
//   try {

//     // Extract the access token
//     const accessToken = authTokens?.access;

//     if (!accessToken) {
//       console.error("No access token found!");
//       return []; // Return an empty array if no access token is found
//     }

//     // Make the fetch request with the access token in the Authorization header
//     const response = await fetch(`${apiUrl}/reviews/create/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${accessToken}`
//       },
//     });

//     if (response.ok) {
//       const responseBody = await response.json();
//       return responseBody;
//     } else {
//       console.error("HTTP error! Status:", response.status);
//       return []; // Return an empty array on error
//     }
//   } catch (error) {
//     console.error("Error fetching user data:", error);
//     return []; // Return an empty array on error
//   }
// };


export const getAllProductsSeller = async ( page = 1, itemsPerPage = 5) => {
  try {

    const response = await fetch(`${apiUrl}/vendor_products/?page=${page}&page_size=${itemsPerPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return { total: 0, items: [] }; // Return an empty object on error
    }
  } catch (error) {
    console.error("Error fetching all products:", error);
    return { total: 0, items: [] }; // Return an empty object on error
  }
};


export const getAllProductsSellerPublic = async (vid, page = 1, itemsPerPage = 5) => {
  try {

    const response = await fetch(`${apiUrl}/vendor_products/${vid}/public?page=${page}&page_size=${itemsPerPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return { total: 0, items: [] }; // Return an empty object on error
    }
  } catch (error) {
    console.error("Error fetching all products:", error);
    return { total: 0, items: [] }; // Return an empty object on error
  }
};


export const getSingleStoreInfo = async () => {
  try {
    // Make the fetch request with the access token in the Authorization header
    const response = await fetch(`${apiUrl}/storeinfo/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      console.error("HTTP error! Status:", response.status);
      return []; // Return an empty array on error
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return []; // Return an empty array on error
  }
};


export const getSingleVendorinfo = async (vid) => {
  try {

    // Make the fetch request with the access token in the Authorization header
    const response = await fetch(`${apiUrl}/vendors/${vid}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseBody = await response.json();
      return responseBody;
    } else {
      return []; // Return an empty array on error
    }
  } catch (error) {
    return []; // Return an empty array on error
  }
};