import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from '@mui/material';
import { getOrderStatusDetails } from '../ApiToFatch';

const OrderDetails = ({ orderId, orders }) => {
    const [orderStatusDetails, setOrderStatusDetails] = useState(null);

    const order = orders.find(order => order.id === orderId);

    useEffect(() => {
        const fetchOrderStatusDetails = async () => {
            try {
                const response = await getOrderStatusDetails(orderId);
                setOrderStatusDetails(response);
            } catch (error) {
                console.error('Error fetching order status details:', error);
            }
        };

        fetchOrderStatusDetails();
        // eslint-disable-next-line
    }, [orderId]); // Fetch details whenever orderId changes

    if (!order) {
        return <Typography variant="h6">Order not found</Typography>;
    }

    const subtotal = order.subtotal;

    // Calculate total price including VAT and delivery charge
    const totalPrice = parseFloat(order.subtotal) + parseFloat(order.delivery_charge);

    const formattedCreatedAt = new Date(order.created_at).toLocaleString();

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
            <Typography variant="h6" align="center" gutterBottom>
                <b>Order ID:</b> {order.formatted_order_id}
            </Typography>

            <Box sx={{ marginY: '28px' }}>
            </Box>

            <Typography variant="body1" gutterBottom>
                <b>Order Placed Date:</b> {formattedCreatedAt}
            </Typography>
            <Typography variant="body1" gutterBottom>
                <b>Order Status:</b> {order.status_title}
            </Typography>
            {order.status_title === 'Delivered' && orderStatusDetails && (
                <Typography variant="body1" gutterBottom>
                    <b>Order Delivery Date:</b> {new Date(orderStatusDetails.datetime).toLocaleString()}
                </Typography>
            )}
            <Typography variant="body1" gutterBottom>
                <b>Order Notes:</b> {order.order_notes}
            </Typography>
            <Typography variant="body1" gutterBottom>
                <b>Phone Number:</b> {order.phone}
            </Typography>
            <Typography variant="body1" gutterBottom>
                <b>Delivery Address:</b>  {order.full_address}
            </Typography>

            <Box sx={{ marginY: '28px' }}>
            </Box>

            <Typography variant="h6" gutterBottom>
                <b>Items:</b>
            </Typography>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="order items table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Product</b></TableCell>
                            <TableCell align="right"><b>Quantity</b></TableCell>
                            <TableCell align="right"><b>Price/unit (৳)</b></TableCell>
                            <TableCell align="right"><b>VAT (%)</b></TableCell>
                            <TableCell align="right"><b>VAT Amount</b></TableCell>
                            <TableCell align="right"><b>Sum</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {order.items.map((item) => {
                            const vatAmount = (item.price * item.vat_percentage) / 100;
                            const totalPricePerItem = (item.price * item.quantity) + vatAmount;

                            return (
                                <TableRow key={item.id}>
                                    <TableCell>{item.product_title}</TableCell>
                                    <TableCell align="right">{item.quantity}</TableCell>
                                    <TableCell align="right">{item.price}</TableCell>
                                    <TableCell align="right">{item.vat_percentage}</TableCell>
                                    <TableCell align="right">{vatAmount.toFixed(2)}</TableCell>
                                    <TableCell align="right">{totalPricePerItem.toFixed(2)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <Box sx={{textAlign: 'end' , padding: '20px'}}>
                    <Typography variant="h6" gutterBottom>
                        <b>Subtotal:</b> ৳ {subtotal}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        <b>Delivery Charge:</b> ৳ {order.delivery_charge}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        <b>Total Price:</b> ৳ {totalPrice.toFixed(2)}
                    </Typography>
                </Box>
            </TableContainer>
        </Box>
    );
};

export default OrderDetails;
