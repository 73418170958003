import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import SearchInput from './SearchInput';
import { apiUrl } from '../ApiToFatch';
import { Link } from 'react-router-dom';

const SearchBar_Nav = () => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = async (query) => {
    setSearchQuery(query); // Update searchQuery state
    if (!query) {
      setFilteredProducts([]);
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/search/?q=${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseBody = await response.json();
        setFilteredProducts(responseBody);
      } else {
        console.error("HTTP error! Status:", response.status);
        setFilteredProducts([]); // Clear the products on error
      }
    } catch (error) {
      console.error("Error fetching search", error);
      setFilteredProducts([]); // Clear the products on error
    }
  };

  const clearSearchResults = () => {
    setSearchQuery(''); // Clear search query
    setFilteredProducts([]); // Clear filtered products
  };

  return (
    <Box className="search-box" sx={{ display: { xs: 'flex', md: 'flex' }, marginLeft: '40px', width: '52%', position: 'relative' }}>
      <SearchInput value={searchQuery} onSearch={handleSearch}/>
      {filteredProducts.length > 0 && (
        <Box className="search-results-container" sx={{ position: 'absolute', top: '100%', left: '0', background: 'white', width: '100%', minHeight: 'fit-content', maxHeight: '300px', overflowY: 'scroll', zIndex: 1, borderRadius: '3px', marginTop: '10px' }}>
          <Box className="search-resultsbox" sx={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '20px' }}>
            {filteredProducts.map((product) => (
              <Link key={product.id} to={`/product-details/${product.id}`} onClick={clearSearchResults} className="search-item" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box className="search-box-items" sx={{
                  display: 'flex', gap: '20px', cursor: 'pointer', padding: '5px',
                  '&:hover': {
                    backgroundColor: '#f0f0f0'
                  }
                }}>
                  <Box className="image-items">
                    <img src={product.image} alt="search-item-img" style={{ width: '50px', height: '50px' }} />
                  </Box>
                  <Box className="text-items">
                    <Typography sx={{ color: 'black' }}><b>Title:</b> {product.title}</Typography>
                    <Typography sx={{ color: 'black' }}> <b>Category:</b> {product.category_name}</Typography>
                  </Box>
                </Box>
              </Link>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SearchBar_Nav;
