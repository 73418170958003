import React, { useState, useContext, useEffect } from 'react';
import BreadCrumb from '../components/BreadCrumb'
import Footer from '../components/Footer/Footer'
import { Box, Container, Grid, Stack, TextField, MenuItem, InputLabel, Select, CircularProgress } from '@mui/material'
// table 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// tabs
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// checkbox
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// button
import { ItemButton } from '../components/custom buttons/Buttons';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrl } from '../components/ApiToFatch';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../redux_store/actions/cartActions';
import AuthContext from '../auth/AuthContext';
import SeoInfo from '../components/seo/SeoInfo';


function ccyFormat(num) {
    const value = parseFloat(num); // Convert string to number
    const roundedValue = Math.round(value);  // Round to nearest integer
    return !isNaN(roundedValue) ? roundedValue.toString() : '0';
}

const formatTotal = (num) => {
    // Ensure num is a number
    const formattedNum = isNaN(num) ? 0 : Number(num); // If it's not a valid number, set it to 0
    return formattedNum.toFixed(2); // Now it's safe to call .toFixed()
};


const Checkout = () => {
    // tabs
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => setValue(newValue);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn, username } = useContext(AuthContext);
    // loading state 
    const [loading, setLoading] = useState(false);
    // state for address
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [deliveryCharge, setDeliveryCharge] = useState([]); // State for the delivery charge
    const [districts, setDistricts] = useState([]);
    const [selectedCharge, setSelectedCharge] = useState([]);
    const [vendorCharges, setVendorCharges] = useState({});


    useEffect(() => {
    }, [selectedCharge]);  // This hook will run every time selectedCharge changes


    // to check address exist or not 
    const [isExistingAddress, setIsExistingAddress] = useState(false);

    // order information details
    const cartItems = useSelector(state => state.cart.items);


    const invoiceSubtotal = cartItems.reduce((acc, item) => {
        const itemTotal = item.quantity * parseFloat(item.product.saleprice);
        const itemTotalWithVAT = itemTotal + (itemTotal * parseFloat(item.product.vat_percentage) / 100);
        return acc + itemTotalWithVAT;
    }, 0);

    const totalDeliveryCharge = selectedCharge ? parseFloat(selectedCharge) : 0;

    // Calculate the invoice total by adding subtotal and total delivery charge
    const invoiceTotal = formatTotal(invoiceSubtotal + totalDeliveryCharge);

    // form state
    const [formValues, setFormValues] = useState({
        firstname: username ? username.firstname : '',
        lastname: username ? username.lastname : '',
        companyname: '',
        city_town: '',
        street: '',
        apartment: '',
        postcode_zip: '',
        phone: username ? username.phonenumber : '',
        email: '',
        ordernote: ''
    });

    // for related states
    const [formErrors, setFormErrors] = useState({});
    const [isAddressSaved, setIsAddressSaved] = useState(false);



    // fetch all addresses by user 
    useEffect(() => {
        if (isLoggedIn) {
            fetch(`${apiUrl}/address/by-user`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            })
                .then(response => response.json())
                .then(data => setAddresses(data))
                .catch(error => console.error('Error fetching addresses:', error));
        }
    }, [isLoggedIn]);


    // Fetch districts and delivery charges when the component mounts or cartItems change
    useEffect(() => {
        if (cartItems.length > 0) {
            const vendorIds = [...new Set(cartItems.map(item => item.product.vendor))];

            if (vendorIds.length > 0) {
                fetch(`${apiUrl}/deliverychargebyvendor/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ vendors: vendorIds }),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Failed to fetch delivery charges');
                        }
                        return response.json();
                    })
                    .then(data => {

                        // Extract unique districts and associated charges from the fetched delivery charges
                        const uniqueDistricts = Array.from(
                            new Map(
                                data.map(item => [
                                    item.district.id,
                                    { id: item.district.id, name: item.district.name },
                                ])
                            ).values()
                        );

                        setDistricts(uniqueDistricts); // Set districts for the dropdown

                        // Normalize delivery charges to always be an array
                        const chargeData = Array.isArray(data)
                            ? data.map(item => ({
                                districtId: item.district.id,
                                charge: parseFloat(item.charge),
                                vendorId: item.vendor,
                            }))
                            : []; // Default to an empty array if data isn't an array

                        setDeliveryCharge(chargeData); // Store delivery charges as an array
                    })
                    .catch(error => console.error('Error fetching delivery charges:', error));
            }
        }
    }, [cartItems]); // Dependency on cartItems

    const handleDistrictChange = (event) => {
        const selectedDistrictName = event.target.value;
    
        // Find the district object from the list of available districts
        const selectedDistrictObj = districts.find((d) => d.name === selectedDistrictName);
        if (!selectedDistrictObj) {
            console.error('District not found for the selected value:', selectedDistrictName);
            return;
        }
    
        if (Array.isArray(deliveryCharge)) {
            const districtCharges = deliveryCharge.filter(
                (dc) => dc.districtId === selectedDistrictObj.id
            );
    
            const vendorCharges = {};
            districtCharges.forEach((dc) => {
                vendorCharges[dc.vendorId] = parseFloat(dc.charge);
            });
    
            const totalCharge = districtCharges.reduce((sum, charge) => sum + charge.charge, 0);
    
            setSelectedDistrict(selectedDistrictName);
            setSelectedCharge(totalCharge);
            setVendorCharges(vendorCharges);
    
            // Update formValues.city_town to synchronize with the selected district
            setFormValues({
                ...formValues,
                city_town: selectedDistrictName,
            });
        } else {
            console.error('deliveryCharge is not an array:', deliveryCharge);
        }
    };
    





    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        setIsExistingAddress(false);
    };

    // change address data as selected data
    const handleAddressChange = (e) => {
        const addressId = e.target.value;
        setSelectedAddress(addressId);

        const selectedAddressData = addresses.find(address => address.id === addressId);
        if (selectedAddressData) {
            setFormValues({
                ...formValues,
                firstname: username ? selectedAddressData.firstname : '',
                lastname: username ? selectedAddressData.lastname : '',
                city_town: selectedAddressData.city_town,
                street: selectedAddressData.street,
                apartment: selectedAddressData.apartment,
                postcode_zip: selectedAddressData.postcode_zip,
                phone: username ? selectedAddressData.phone : ''
            });
            setIsExistingAddress(true);
        }
    };


    const validateForm = () => {
        const errors = {};
        if (!formValues.firstname) errors.firstname = 'First name is required';
        if (!formValues.lastname) errors.lastname = 'Last name is required';
        if (!formValues.city_town) errors.city_town = 'city/town is required';
        if (!formValues.street) errors.street = 'Street address is required';
        if (!formValues.phone) errors.phone = 'Phone number is required';
        return errors;
    };

    const handleAddressFormSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsAddressSaved(true);
        } else {
            setIsAddressSaved(false);
        }
    };


    const handlePlaceOrder = async () => {
        setLoading(true);
        try {
            let addressId;

            // Step 1: Determine the address ID
            if (isExistingAddress) {
                addressId = selectedAddress; // Use the selected address ID if it exists
            } else {
                // Create a new address
                const addressResponse = await fetch(`${apiUrl}/address/create/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formValues),
                    credentials: 'include',
                });

                if (!addressResponse.ok) {
                    console.error('Failed to create address');
                    setLoading(false);
                    return;
                }

                const addressData = await addressResponse.json();
                addressId = addressData.id; // Use the newly created address ID
            }

            // Step 2: Prepare the order payload
            const itemsPayload = cartItems.map(item => ({
                product_title: item.product.title,
                quantity: item.quantity,
                vendor: item.product.vendor,
                vat_percentage: parseFloat(item.product.vat_percentage),
                price: parseFloat(item.product.saleprice),
            }));

            // Calculate totals
            const roundedSubtotal = Math.round(invoiceSubtotal);
            const roundedTotalDeliveryCharge = Math.round(totalDeliveryCharge);
            const roundedInvoiceTotal = Math.round(invoiceSubtotal + totalDeliveryCharge);

            // Construct the `delivery_charges` field using `vendorCharges`
            const deliveryChargesPayload = Object.entries(vendorCharges).reduce((acc, [vendorId, charge]) => {
                acc[vendorId] = charge; // Create a mapping of vendor IDs to their charges
                return acc;
            }, {});

            // Construct the order payload
            const orderPayload = {
                address: addressId,
                district: selectedDistrict, // Include the selected district
                order_notes: formValues.ordernote,
                subtotal: roundedSubtotal,
                delivery_charge: roundedTotalDeliveryCharge,
                total: roundedInvoiceTotal,
                delivery_charges: deliveryChargesPayload, // Include vendor-specific delivery charges
                items: itemsPayload,
            };


            // Step 3: Send the order payload to the server
            const orderResponse = await fetch(`${apiUrl}/order/create/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderPayload),
                credentials: 'include',
            });

            if (orderResponse.status === 201) {
                // Success: Clear cart and redirect
                dispatch(clearCart());
                navigate('/order-success');
            } else {
                const errorData = await orderResponse.json();
                console.error('Failed to create order:', errorData);
            }
        } catch (error) {
            console.error('Error placing order:', error);
        } finally {
            setLoading(false);
        }
    };





    return (
        <>
            <SeoInfo
                title='CheckOut'
                description='Checkout page on Ea-te'
                creatorname='Ea-te'
            />
            <div className="checkout-container">
                <BreadCrumb page_title="CHECKOUT" />
                <Container className="checkout-main">
                    <Grid container spacing={2} justifyContent={'space-between'} sx={{ marginY: '40px' }}>
                        <Grid item xs={12} md={6}>
                            <Box className="checkout-details-form">
                                <h3>BILLING DETAILS</h3>
                                <hr />
                                <form className='address-form' onSubmit={handleAddressFormSubmit}>
                                    <Box className="address-selection">
                                        <InputLabel variant="standard" htmlFor="address-select">
                                            Saved Addresses
                                        </InputLabel>
                                        <Select
                                            id="address-select"
                                            value={selectedAddress}
                                            onChange={handleAddressChange}
                                            displayEmpty
                                            fullWidth
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>Select here</em>
                                            </MenuItem>
                                            {Array.isArray(addresses) && addresses.length > 0 ? (
                                                addresses.map((address) => (
                                                    <MenuItem key={address.id} value={address.id}>
                                                        {address.city_town}, {address.street}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No addresses available</MenuItem>
                                            )}
                                        </Select>
                                    </Box>
                                    <Stack gap={'20px'} marginTop={'40px'}>
                                        <Box className="customar-name">
                                            <Grid container spacing={4}>
                                                <Grid item xs={6}>
                                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                        First name *
                                                    </InputLabel>
                                                    <TextField
                                                        required
                                                        id="customar-firstname-input"
                                                        name="firstname"
                                                        type="text"
                                                        variant="outlined"
                                                        color="primary"
                                                        value={formValues.firstname}
                                                        onChange={handleInputChange}
                                                        error={!!formErrors.firstname}
                                                        helperText={formErrors.firstname}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                        Last name *
                                                    </InputLabel>
                                                    <TextField
                                                        required
                                                        id="customar-lastname-input"
                                                        name="lastname"
                                                        type="text"
                                                        variant="outlined"
                                                        color="primary"
                                                        value={formValues.lastname}
                                                        onChange={handleInputChange}
                                                        error={!!formErrors.lastname}
                                                        helperText={formErrors.lastname}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className="companyname">
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Company name (optional)
                                            </InputLabel>
                                            <TextField
                                                id="company-name"
                                                name="companyname"
                                                type="text"
                                                variant="outlined"
                                                color="primary"
                                                value={formValues.companyname}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className="region">
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Deliverable Districts (select your's) *
                                            </InputLabel>
                                            <Select
                                                name="city_town"
                                                value={selectedDistrict || ''}
                                                onChange={handleDistrictChange}
                                                error={!!formErrors.city_town}
                                                MenuProps={{
                                                    disableScrollLock: true,
                                                }}
                                            >
                                                {districts.map(district => (
                                                    <MenuItem key={district.id} value={district.name}>
                                                        {district.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formErrors.city_town && (
                                                <p style={{ color: 'red', margin: '0' }}>{formErrors.city_town}</p>
                                            )}
                                        </Box>

                                        <Box className="streat-address" sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Street address *
                                            </InputLabel>
                                            <TextField
                                                required
                                                id="streat-address-one"
                                                name="street"
                                                type="text"
                                                variant="outlined"
                                                placeholder='House Address & Streat Name'
                                                color="primary"
                                                value={formValues.street}
                                                onChange={handleInputChange}
                                                error={!!formErrors.street}
                                                helperText={formErrors.street}
                                            />
                                            <TextField
                                                required
                                                id="streat-address-two"
                                                name="apartment"
                                                type="text"
                                                variant="outlined"
                                                placeholder='Apartment , Suite , etc (optional)'
                                                color="primary"
                                                value={formValues.apartment}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className="post-code">
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Postcode / ZIP (optional)
                                            </InputLabel>
                                            <TextField
                                                id="post-code"
                                                name="postcode_zip"
                                                type="text"
                                                variant="outlined"
                                                color="primary"
                                                value={formValues.postcode_zip}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className="phone">
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Phone *
                                            </InputLabel>
                                            <TextField
                                                required
                                                id="phone"
                                                name="phone"
                                                type="number"
                                                variant="outlined"
                                                color="primary"
                                                value={formValues.phone}
                                                onChange={handleInputChange}
                                                error={!!formErrors.phone}
                                                helperText={formErrors.phone}
                                            />
                                        </Box>
                                        {/* <Box className="email">
                                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                    Email
                                                </InputLabel>
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    variant="outlined"
                                                    color="primary"
                                                    value={formValues.email}
                                                    onChange={handleInputChange}
                                                    error={!!formErrors.email}
                                                    helperText={formErrors.email}
                                                />
                                            </Box> */}
                                        <Box>
                                            <ItemButton fullWidth type="submit" disabled={isAddressSaved === true}>
                                                {isAddressSaved === false ? (
                                                    <>Save & Continue</>
                                                ) : (
                                                    <>Saved</>
                                                )}
                                            </ItemButton>
                                        </Box>
                                    </Stack>
                                </form>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box className="checkout-aditional-info">
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Order notes (optional)
                                </InputLabel>
                                <TextField
                                    multiline
                                    id="order-note"
                                    name="ordernote"
                                    variant="outlined"
                                    color="primary"
                                    placeholder='Add your order notes here.'
                                    fullWidth
                                    minRows={6}
                                    value={formValues.ordernote}
                                    onChange={handleInputChange}
                                    sx={{ minHeight: '120px' }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className="checkout-order-details">
                        <h3>YOUR ORDER</h3>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" colSpan={3}>
                                            <b>Details</b>
                                        </TableCell>
                                        <TableCell align="center" colSpan={2}><b>Price</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>Description</b></TableCell>
                                        <TableCell align="right"><b>Qty.</b></TableCell>
                                        <TableCell align="right"><b>Vat</b></TableCell>
                                        <TableCell align="right"><b>price/unit</b></TableCell>
                                        <TableCell align="right"><b>Sum</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cartItems.map((item) => (
                                        <TableRow key={item.product.id}>
                                            <TableCell>{item.product.title}</TableCell>
                                            <TableCell align="right">{item.quantity}</TableCell>
                                            <TableCell align="right">{item.product.vat_percentage}%</TableCell>
                                            <TableCell align="right">{ccyFormat(parseFloat(item.product.saleprice))}</TableCell>
                                            <TableCell align="right">
                                                {ccyFormat(
                                                    item.quantity * parseFloat(item.product.saleprice) * (1 + parseFloat(item.product.vat_percentage) / 100)
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell rowSpan={3} />
                                        <TableCell colSpan={3}><b>Subtotal</b></TableCell>
                                        <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3}><b>Delivery Charge</b></TableCell>
                                        <TableCell align="right">{ccyFormat(selectedCharge)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3}><b>Total</b></TableCell>
                                        <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box className="payment-method-order" sx={{ marginTop: "40px" }}>
                            <h3>Payments Methods</h3>
                            <Stack className="payment-method">
                                <Box>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                <Tab label="Cash on delivery" value="1" />
                                                <Tab label="Online Payments" value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <Stack className="cash-on-delivery">
                                                <FormControlLabel control={<Checkbox defaultChecked />} label="Cash On Delivery" />
                                            </Stack>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <p>Not Available Right Now! </p>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Stack>
                            <Box sx={{ paddingTop: '40px' }}>
                                <ItemButton fullWidth disabled={!isAddressSaved || loading || cartItems.length === 0} onClick={handlePlaceOrder} sx={{ position: 'relative' }} >
                                    {loading && <CircularProgress size={15} sx={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-40%, -50%)' }} />}
                                    Place Order
                                </ItemButton>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Footer />
            </div>
        </>
    )
}

export default Checkout