import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../redux_store/actions/wishlistActions';
import { ItemButton } from './Buttons';
import { Stack, Typography } from '@mui/material';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import AuthContext from '../../auth/AuthContext';
import { apiUrl } from '../ApiToFatch';

const AddToWishlistButton = ({ product }) => {
    const dispatch = useDispatch();
    const wishlistItems = useSelector(state => state.wishlist.items);
    const [isInWishlist, setIsInWishlist] = useState(false);
    const { authTokens , username } = useContext(AuthContext);

    useEffect(() => {
        // Check if product is already in wishlist when component mounts
        if (product && product.id) {
            const foundItem = wishlistItems.some(item => item.id === product.id);
            setIsInWishlist(foundItem);
        }
    }, [product, wishlistItems]);

    const handleAddToWishlist = async () => {
        // Check if user is logged in (authTokens available)
        if (authTokens?.access) {
            // User is logged in, save wishlist data to database
            try {
                const response = await addToDatabase(product);
                if (response.ok) {
                    dispatch(addToWishlist(product)); // Update Redux state
                    setIsInWishlist(true); // Update local state
                } else {
                    console.error("Failed to add to wishlist:", response.status);
                    // Handle error as needed
                }
            } catch (error) {
                console.error("Error adding to wishlist:", error);
                // Handle error as needed
            }
        } else {
            // User is not logged in, save wishlist data to localStorage
            try {
                addToLocalStorage(product); // Update localStorage directly
                dispatch(addToWishlist(product)); // Update Redux state
                setIsInWishlist(true); // Update local state
            } catch (error) {
                console.error("Error adding to local wishlist:", error);
                // Handle error as needed
            }
        }
    };

    const handleRemoveFromWishlist = async () => {
        // Check if user is logged in (authTokens available)
        if (authTokens?.access) {
            // User is logged in, remove wishlist data from database
            try {
                const response = await removeFromDatabase(product.id);
                if (response.ok) {
                    dispatch(removeFromWishlist(product.id)); // Update Redux state
                    setIsInWishlist(false); // Update local state
                } else {
                    console.error("Failed to remove from wishlist:", response.status);
                    // Handle error as needed
                }
            } catch (error) {
                console.error("Error removing from wishlist:", error);
                // Handle error as needed
            }
        } else {
            // User is not logged in, remove wishlist data from localStorage
            try {
                removeFromLocalStorage(product.id); // Update localStorage directly
                dispatch(removeFromWishlist(product.id)); // Update Redux state
                setIsInWishlist(false); // Update local state
            } catch (error) {
                console.error("Error removing from local wishlist:", error);
                // Handle error as needed
            }
        }
    };

    const addToDatabase = async (product) => {
        const accessToken = authTokens?.access;
        return await fetch(`${apiUrl}/add-to-wishlist/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ product: product.id })
        });
    };

    const removeFromDatabase = async (productId) => {
        const accessToken = authTokens?.access;
        return await fetch(`${apiUrl}/remove-from-wishlist/${productId}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    };

    const addToLocalStorage = (product) => {
        let currentWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
        currentWishlist = [...currentWishlist, product];
        localStorage.setItem('wishlist', JSON.stringify(currentWishlist));
    };

    const removeFromLocalStorage = (productId) => {
        let currentWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
        currentWishlist = currentWishlist.filter(item => item.id !== productId);
        localStorage.setItem('wishlist', JSON.stringify(currentWishlist));
    };

    const userRole = username?.role || '';

    return (
        <div className="add-to-wishlist-button">
             {isInWishlist ? (
                <ItemButton onClick={handleRemoveFromWishlist} fullWidth disabled={userRole === 'vendors'}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FavoriteOutlinedIcon fontSize="small" />
                        <Typography>Remove from Wishlist</Typography>
                    </Stack>
                </ItemButton>
            ) : (
                <ItemButton onClick={handleAddToWishlist} fullWidth disabled={userRole === 'vendors'}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FavoriteBorderOutlinedIcon fontSize="small" />
                        <Typography>Add to Wishlist</Typography>
                    </Stack>
                </ItemButton>
            )}
        </div>
    );
};

export default AddToWishlistButton;
