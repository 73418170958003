import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeFromWishlist } from '../../redux_store/actions/wishlistActions';
import { Stack, Typography, List, ListItem, ListItemText, Box, Button } from '@mui/material'; // Import MUI components
import AddToCartButton from '../custom buttons/AddToCartButton ';
import { getWishlistItems } from '../ApiToFatch';
import AuthContext from '../../auth/AuthContext';
import { truncateTitle } from '../utils/UtilityFunctions'
import { apiUrl } from '../ApiToFatch';

const WishListItems = () => {
    const dispatch = useDispatch();
    const { isLoggedIn } = useContext(AuthContext);
    const [wishlistItems, setWishlistItems] = useState([]);

    useEffect(() => {
        fetchWishlistData(); // Fetch wishlist items when component mounts
        // eslint-disable-next-line
    }, []);

    const fetchWishlistData = async () => {
        try {
            const data = await getWishlistItems(); // Implement getWishlistItems API method

            // Fetch detailed information for each wishlist item
            const detailedItems = await Promise.all(
                data.map(async (item) => {
                    const detailedResponse = await fetch(`${apiUrl}/products/${item.product}/`);
                    const detailedData = await detailedResponse.json();
                    return { ...item, product: detailedData }; // Replace 'product' with detailed data
                })
            );

            setWishlistItems(detailedItems); // Set fetched wishlist items with detailed data to state
        } catch (error) {
            console.error('Error fetching wishlist items:', error);
        }
    };


    const handleRemoveFromWishlist = async (productId) => {
        // Check if user is logged in (authTokens available)
        if (isLoggedIn) {
            try {
                const response = await removeFromDatabase(productId);
                if (response.ok) {
                    dispatch(removeFromWishlist(productId)); // Update Redux state
                    setWishlistItems(wishlistItems.filter(item => item.product.id !== productId)); // Update local state
                } else {
                    console.error("Failed to remove from wishlist:", response.status);
                }
            } catch (error) {
                console.error("Error removing from wishlist:", error);
            }
        }
    };

    const removeFromDatabase = async (productId) => {
        return await fetch(`${apiUrl}/remove-from-wishlist/${productId}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
    };

    return (
        <div className="wishlist-items">
            {wishlistItems.length > 0 ? (
                <List sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {wishlistItems.map((item) => (
                        <ListItem key={item.product.id} sx={{ border: '1px solid #ccc', borderRadius: '5px', marginBottom: '8px', '&:hover': { backgroundColor: '#f0f0f0' } }}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2} sx={{ justifyContent: 'space-between', width: '100%' }} >
                                <img
                                    src={item.product.image ? item.product.image : 'placeholder-url.jpg'}
                                    alt="product img"
                                    style={{ width: '50px', height: 'auto' }}
                                />
                                <ListItemText
                                    primary={
                                        <Link to={`/product-details/${item.product.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            {item.product.title ? truncateTitle(item.product.title, 30) : 'title'}
                                        </Link>
                                    }
                                />
                                <Box sx={{ display: 'flex', gap: { xs: '15px', md: '20px' } }}>
                                    <AddToCartButton product={item.product} />
                                    <Button variant="outlined" color="primary" onClick={() => handleRemoveFromWishlist(item.product.id)}>
                                        Remove
                                    </Button>
                                </Box>
                            </Stack>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography variant="body1">Nothing to show, please add items to your wishlist.</Typography>
            )}
        </div>
    );
};

export default WishListItems;
